/** @jsxImportSource theme-ui */
import * as React from "react";

import Image from "../components/image";
import CTA from "../components/cta";

const ImageCTA = ({ data }) => {
  const { cta, backgroundImage, heading, anchor } = data;
  return (
    <div
      id={anchor}
      sx={{
        display: "grid",
        height: "34rem",
        overflow: "hidden",
      }}
    >
      <Image
        source={backgroundImage}
        sx={{
          gridArea: "1/-1",
          objectFit: "cover",
          width: "100%",
          height: "100%",
          minHeight: "0",
        }}
      ></Image>
      <div
        sx={{
          gridArea: "1/-1",
          alignSelf: "center",
          justifySelf: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: ["100%", "100%", "38rem"],
        }}
      >
        <p
          dangerouslySetInnerHTML={{ __html: heading }}
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: ["2rem", "2rem", "2.5rem"],
            textAlign: "center",
            mb: "2rem",
          }}
        />
        {/* eslint-disable-next-line */}
        <CTA
          cta={cta}
          sx={{
            width: "fit-content",
          }}
        />
      </div>
    </div>
  );
};

export default ImageCTA;
